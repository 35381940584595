import React, { useState } from "react"
import styles from "./App.module.css"
import { Routes, Route } from "react-router-dom"
import Toast from "./components/generic/Toast"
import Header from "./components/generic/Header"
import Home from "./components/Home"

export default function App() {
  const [toast, setToast] = useState()

  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.content}>
        <Routes>
          <Route path="/" element={<Home setToast={setToast} />}></Route>
        </Routes>
      </div>

      <Toast toast={toast} setToast={setToast}></Toast>
    </div>
  )
}
