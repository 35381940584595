import React from 'react';
import logo from '../../images/logo.svg';
import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';

const Header = ({inschrijving}) => {
  let navigate = useNavigate()
  return (
    <div className={styles.wrapper}>
      <div className={styles.topbar}></div>
      <div className={styles.main}>
        <div className={styles.left}>
          <img className={styles.logo} src={logo} alt="logo" />
          {/* <div className={styles.title}>Mijn eenmanszaak</div> */}
          <span style={{color:'red', fontWeight:'bold',paddingLeft:'6px'}}> Demo</span>
        </div>

        <div className={styles.mainPrivate}>
          <a
            href="#nothing"
            style={{ marginLeft: '6px', fontSize: '0.9em' }}
            onClick={(event) => {
              window.location.reload()
            }}
          >
            Uitloggen
          </a>
          {/* <div>Uitloggen</div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
