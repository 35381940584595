import React, { useEffect } from 'react';
import styles from './Toast.module.css';

export default function Toast({ toast, setToast }) {
  useEffect(() => {
    let timeout = setTimeout(() => {
      setToast(null);
    }, 3700);

    return () => clearTimeout(timeout);
  }, [toast, setToast]);

  if (!toast) return null;
  
  return (
    <div
      style={{ backgroundColor: toast.color || '#cc2020' }}
      className={styles.main}
    >
      {toast.message}
    </div>
  );
}
