import React, { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import axios from "axios"
import Button from "./generic/Button"
import styles from "./Home.module.css"
// import { Link } from 'react-router-dom';

function Block({ header, body1, body2 }) {
  return (
    <div className={styles.block}>
      <h4 style={{margin:'12px 0'}}>{header}</h4>
      <div>{body1}</div>
      <div>{body2}</div>
    </div>
  )
}

export default function Home({ setToast }) {
  let [searchParams, setSearchParams] = useSearchParams()
  let [disclosedAttributes, setDisclosedAttributes] = useState()
  // let [diclosedAttributes, setDisclosedAttributes] = useState({
  //   "attributes": {
  //     "com.example.pid": {
  //       "attributes": {
  //         "com.example.pid": {
  //           "bsn": "999991772"
  //         }
  //       },
  //       "issuer": "pid.example.com",
  //       "ca": "ca.pid.example.com",
  //       "validityInfo": {
  //         "signed": "2024-10-19T13:16:43Z",
  //         "validFrom": "2024-10-19T13:16:38Z",
  //         "validUntil": "2025-10-19T13:16:38Z"
  //       }
  //     }
  //   }
  // })

  // Create a ref for the web component
  const walletButtonRef = useRef(null)

  // Function to handle the 'success' event
  const handleSuccess = (e) => {
    if (e.detail && e.detail.length > 1) {
      const session_token = e.detail[0]
      const session_type = e.detail[1]

      // this only works for cross_device without a configured return URL
      if (session_type === "cross_device") {
        setSearchParams({ session_token })
      }
    }
    console.log("Success event received:", e.detail)
  }

  const handleFailed = (e) => {
    console.log("Failed event received:", e.detail)
    setToast({
      message: "Er ging iets mis.",
      color: "#cc2020",
    })
  }

  useEffect(() => {
    let sessionToken = searchParams.get("session_token")
    let nonce = searchParams.get("nonce")

    if (sessionToken) {
      let url = `/disclosed-attributes?session_token=${sessionToken}`
      if (nonce) url = `${url}&nonce=${nonce}`
      axios
        .get(url)
        .then(({ data }) => {
          setDisclosedAttributes(data)
          setSearchParams()
        })
        .catch((error) => {
          console.log(error.message)

          setToast({
            message: "Er ging iets mis met laden.",
            color: "#cc2020",
          })
        })
    }
  }, [searchParams])

  useEffect(() => {
    const walletButton = walletButtonRef.current

    // Attach the event listener when the component mounts
    if (walletButton) {
      walletButton.addEventListener("success", handleSuccess)
      walletButton.addEventListener("failed", handleFailed)
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (walletButton) {
        walletButton.removeEventListener("success", handleSuccess)
        walletButton.removeEventListener("failed", handleFailed)
      }
    }
  }, [])

  function renderDisclosedAttributes() {
    let bsn = disclosedAttributes.attributes["com.example.pid"]?.attributes["com.example.pid"]?.bsn
    return <span style={{ paddingLeft: "12px", fontSize: "0.9em" }}>bsn: {bsn}</span>
  }

  return (
    <>
      <div>
        {/* <svg width="89px" height="24px" viewBox="0 0 89 24" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path fill="#00526E" fill-rule="nonzero" d="M71.4833333,14.7166667 C71.275,14.375 71.1333333,14.1916667 70.95,14.0833333 C70.55,13.8416667 69.9916667,13.9916667 69.7416667,14.4166667 C69.5916667,14.6916667 69.5916667,15.3 69.5916667,15.5083333 L69.5916667,24 L60,24 L60,0 L69.6,0 L69.6,10.125 L76.9166667,0 L87.4833333,0 L79.2083333,10.5583333 L88.1666667,24 L77.225,24 L71.4833333,14.7166667 Z M37.3416667,24 L48.1833333,24 L57.0916667,0 L47.3833333,0 L43.1833333,13.3833333 L43.1166667,13.3833333 L38.9583333,0 L28.8333333,0 L37.3416667,24 Z M17.225,24 L28.1666667,24 L19.2083333,10.5583333 L27.4833333,0 L16.9166667,0 L9.6,10.125 L9.6,0 L0,0 L0,24 L9.59166667,24 L9.59166667,15.5083333 C9.59166667,15.3 9.59166667,14.6916667 9.74166667,14.4166667 C9.99166667,13.9916667 10.55,13.8416667 10.95,14.0833333 C11.1333333,14.1916667 11.275,14.375 11.4833333,14.7166667 L17.225,24 Z"></path></g></svg>
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="64" height="64" rx="12" fill="#FF0000"/><path d="M25 53.1823L29.1985 48.9481L25 44.7139L27.8015 41.8886L32 46.1228L36.1985 41.8886L39 44.7139L34.8015 48.9481L39 53.1823L36.191 56L31.9925 51.7658L27.794 56L25 53.1823ZM25 19.2861L29.1985 15.0519L25 10.8253L27.8015 8L32 12.2342L36.191 8L38.9925 10.8253L34.794 15.0595L38.9925 19.2937L36.191 22.1114L31.9925 17.8772L27.794 22.1114L25 19.2861ZM25 36.2455L29.1985 32.0114L25 27.7848L27.8015 24.9594L32 29.1936L36.1985 24.9594L39 27.7848L34.8015 32.0189L39 36.2531L36.191 39.0709L31.9925 34.8367L27.794 39.0709L25 36.2455Z" fill="white"/></svg> */}
        <h1>Mijn KVK</h1>
      </div>
      <p>
        Je bent ingelogd bij Mijn KVK. Hieronder zie je de organisaties waar je een functie vervult
        en de gegevens van de organisaties.
        <br />
        Mis je organisaties? Er zijn verschillende redenen waarom niet alle organisaties getoond
        worden.
      </p>

      <h2 style={{ paddingTop: "30px", marginBottom: "12px" }}>Organisaties</h2>

      <div className={styles.orgHeader}>
        <h3 style={{ paddingRight: "30px" }}>Witbaard Feestartikelen</h3>

        {disclosedAttributes ? (
          <>
            <button
              onClick={() =>
                setToast({
                  message: "Deze functionaliteit moet nog geimplementeerd worden.",
                  color: "#00526e",
                })
              }
            >
              Voeg toe aan mijn wallet 2: Issue data
            </button>
            {disclosedAttributes && renderDisclosedAttributes()}
          </>
        ) : (
          <nl-wallet-button
            ref={walletButtonRef}
            text="Voeg toe aan mijn wallet 1: Deel BSN"
            usecase="mijn_kvk"
            start-url={`/create-session?lang=en`}
            lang="nl"
          ></nl-wallet-button>
        )}
      </div>
      <div>
        KVK-nummer: 90000001
        <br />
        Functie(s): Eigenaar
      </div>
      <div className={styles.blockContainer}>
        <Block
          header="Bedrijfsactiviteiten"
          body1="SBI code: 6201"
          body2="Ontwikkelen, produceren en uitgeven van software"
        ></Block>
        <Block header="Bezoekadres" body1="Dorpstraat 4" body2="100AA Lutjebroek"></Block>
        <Block header="Telefoonnummer" body1="(+31) 6 12345678" body2=""></Block>
      </div>
      <div>
        <a href="">Wijzig bedrijfsgegevens</a>
      </div>
    </>
  )
}
